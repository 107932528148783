
.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.container--main-page {
    max-width: 1606px;
}

.section {
    margin-top: 180px;
}

section:first-of-type {
    margin-top: 0;
}

//Для приближения фотографий

.large-image {
    max-height: 90vh;
    max-width: 90vw;
    object-fit: cover;
}

.modal__img-close {
    position: absolute;
    top: 30px;
    right: 30px;
}

// для стилизация сервиса с формой обратного звонка
#cbw-gudok, #cbw-buttonContainer .cbw-default#cbw-buttonShadow, #cbw-popupContainer #cbw-popupVector_1, #cbw-popupContainer #cbw-popupVector_2 {display: none !important}
#cbw-buttonContainer .cbw-default#cbw-buttonButton, #cbw-popupContainer .cbw-default#cbw-formButton {background: #604fe3 !important;}
#cbw-formButton, #cbw-formTel, #cbw-popupContainer.cbw-right, #cbw-popupContainer.cbw-left {border-radius: 4px !important;}
#cbw-formTel {border: 2px solid #75767e !important;}
#cbw-popupContainer {box-shadow: 0 25px 150px rgba(0, 0, 0, 0.2) !important;}
#cbw-popupContainer .cbw-default#cbw-formButton {box-shadow: none !important;}


@media (max-width: 1700px) {
    .container {
        max-width: 1300px;
    }
}

@media (max-width: 1500px) {
    .container {
        max-width: 900px;
    }

    .container--header {
        max-width: 1100px;
    }

    .container--footer {
        max-width: 1100px;
    }
}

@media (max-width: 1200px) {
    .container {
        max-width: 833px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: none;
        padding: 0 80px;
    }

    .section {
        margin-top: 100px;
    }
}

@media (max-width: 767px) {
    .container {
        padding: 0 40px;
    }

    .section {
        margin-top: 80px;
    }
}

@media (max-width: 575px) {

    #root {
        overflow: hidden;
    }
    .container {
        padding: 0 16px;
    }

    .container--main-page-services {
        padding-right: 0;
    }

    // перемещение иконки чата
    #__replain_widget {
        z-index: 900 !important;
        inset: auto 20px 72px auto !important;
    }

    
    
}
