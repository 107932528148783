.conditions {
    overflow: hidden;
}
.conditions__heading {
    margin-bottom: 36px;
}

.conditions__items {
    width: 1754px;
    height: 414px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.conditions__items::-webkit-scrollbar {
    display: none;
}

.conditions__item {
    flex-shrink: 0;
    display: flex;
    margin-right: 397px;
}
.conditions__item:last-of-type {
    margin-right: 0;
}
.conditions__img-wrapper {
    align-self: flex-start;
    position: relative;
    margin-right: 36px;
}
.conditions__img {
}
.conditions__item-arrow {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -24px;
    display: block;
    height: 2px;
    background-color: #242426;
}

.conditions__item-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: -4px;
    right: -3px;
    background-color: #242426;
    transform: rotate(45deg);
}
.conditions__item-arrow::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: 3px;
    right: -3px;
    background-color: #242426;
    transform: rotate(-45deg);
}

.conditions__text-wrapper {
    align-self: flex-end;
    max-width: 381px;
    padding-bottom: 28px;
}
.conditions__item-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #242426;
    margin-bottom: 4px;
}
.conditions__item-description {
    font-size: 18px;
    line-height: 140%;
    color: #242426;
}

.conditions__fake-item {
    display: none;
    flex-shrink: 0;
    height: 100px;
    width: 100px;
    background-color: white;
}

@media (max-width: 1759px) {
    .conditions__fake-item {
        display: block;
        width: 38px;
    }
}

@media (max-width: 1700px) {
    .conditions__fake-item {
        width: 300px;
    }
}

@media (max-width: 1500px) {
    .conditions__fake-item {
        width: 650px;
    }

    .conditions__img {
        height: 360px;
    }

    .conditions__item {
        margin-right: 133px;
    }

    .conditions__item-title {
        font-size: 22px;
    }

    .conditions__item-description {
        font-size: 17px;
    }

    .conditions__text-wrapper {
        padding-bottom: 51px;
    }
}

@media (max-width: 1200px) {
    .conditions__fake-item {
        display: none;
    }

    .conditions__items {
        width: 100%;
        height: auto;
        flex-direction: column;
        overflow-x: unset;
    }

    .conditions__item {
        margin-right: 0;
        margin-bottom: 135px;
    }

    .conditions__item:last-of-type {
        margin-bottom: 37px;
    }

    .conditions__item-title {
        font-size: 20px;
    }

    .conditions__img-wrapper {
        margin-right: 24px;
    }

    .conditions__img {
        height: auto;
        width: 320px;
    }

    .conditions__item-description {
        font-size: 16px;
    }

    .conditions__text-wrapper {
        max-width: 333px;
        padding-bottom: 0;
    }

    .conditions__item:nth-child(2n) {
        align-self: flex-end;
    }

    .conditions__item:nth-child(2n) .conditions__img-wrapper {
        order: 2;
        margin-right: 0;
        margin-left: 24px;
    }

    .conditions__item:nth-child(2n) .conditions__text-wrapper {
        order: 1;
    }
}

@media (max-width: 992px) {
    .conditions__item {
        margin-bottom: 123px;
    }

    .conditions__img {
        width: 208px;
    }

    .conditions__text-wrapper {
        max-width: 293px;
    }

    .conditions__item-title {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .conditions__item-description {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .conditions__item {
        margin-bottom: 105px;
    }

    .conditions__img {
        width: 179px;
    }

    .conditions__item-title {
        font-size: 17px;
    }
}

@media (max-width: 575px) {
    .conditions__heading {
        margin-bottom: 24px;
    }

    .conditions__item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 54px;
    }

    .conditions__item:last-of-type {
        margin-bottom: 3px;
    }

    .conditions__item:nth-child(2n) {
        align-self: unset;
    }

    .conditions__item:nth-child(2n) .conditions__img-wrapper {
        order: unset;
        margin-left: 0;
    }

    .conditions__item:nth-child(2n) .conditions__text-wrapper {
        order:unset;
    }

    .conditions__img-wrapper {
        margin-right: 0;
        margin-bottom: 52px;
        align-self:unset;
    }

    .conditions__img {
        width: 142px;
    }

    .conditions__text-wrapper {
        max-width: 293px;
        align-self: unset;
    }

    .conditions__item-title {
        font-size: 16px;
        text-align: center;
    }

    .conditions__item-description {
        text-align: center;
    }

    .conditions__item-arrow {
        bottom: -16px;
    }
    
}

@media (max-width: 359px) {
}
